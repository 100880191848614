import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import ServiceCards from "./ServiceCards"
import { useInView } from "react-intersection-observer"
import { P } from "../../styles/shared.styled"
import { graphql, useStaticQuery } from "gatsby"
import { mapOurServices } from "../../utils/mappers/mapOurServices"

const SectionTwoContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
  padding: 32px 14px;
`

const StyledP = styled(P)`
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
          inView ? "translateX(0)" : "translateX(-800px)"};
  transition: all 0.9s ease;
  margin: 0;
`

const ServiceCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33.33%, 1fr));
  max-width: 1294px;
  margin: 24px auto 0;
  flex-wrap: wrap;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    grid-template-columns: 100%;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    grid-template-columns: 100%;
  }

`

const getCategories = graphql`
{
  categories: allContentfulCategories(sort: {fields : createdAt, order: ASC}) {
    nodes {
      category,
      subCategories
    }
  }
}
`

const OurServices = ({ withAbout = true }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true
  })
  const { categories: { nodes } } = useStaticQuery(getCategories)
  return (
    <SectionTwoContainer flexDirection="column">
      <FlexContainer gap="14px" flexDirection="column" ref={ref}>
        {withAbout && (
          <StyledP inView={inView} margin="0 0 20px">
            ABOUT US
          </StyledP>
        )}
        <StyledP
          inView={inView}
          fontWeight="bold"
          fontSize="24px"
        >
          OUR SERVICES
        </StyledP>
      </FlexContainer>
      <ServiceCardsContainer>
        {mapOurServices(nodes).map(({ id, icon, description, title, location }) => (
          <ServiceCards
            key={id}
            title={title}
            icon={icon}
            description={description}
            location={location}
          />
        ))}
      </ServiceCardsContainer>
    </SectionTwoContainer>
  )
}

export default OurServices
